import Api from '@/axios'

export default {
  createCategory (payload) {
    return Api().post('accounts/settings/categories/store', payload)
  },
  getAllCatgory () {
    return Api().get('accounts/settings/categories?parent=1')
  },
  getAllCatgoryByParent (id) {
    return Api().get(`accounts/settings/categories/${id}`)
  },
  getAllClassefications () {
    return Api().get('accounts/settings/classifications')
  },
  addNewClassefications (data) {
    return Api().post('accounts/settings/classifications/store', data)
  },
  deleteClassefications (id) {
    return Api().delete(`accounts/settings/classifications/${id}`)
  },
  switchStatus (id) {
    return Api().patch(`accounts/settings/classifications/status/${id}`)
  },
  getAllpaymentMethods () {
    return Api().get('accounts/settings/payment-methods')
  },
  addNewpaymentMethods (data) {
    return Api().post('accounts/settings/payment-methods/store', data)
  },
  deletepaymentMethods (id) {
    return Api().delete(`accounts/settings/payment-methods/${id}`)
  },
  switchStatusPayment (id) {
    return Api().patch(`accounts/settings/payment-methods/status/${id}`)
  },
  getALlDataToCreateEntries () {
    return Api().get('accounts/entries/create')
  },
  createEntries (payload) {
    return Api().post('accounts/entries/store', payload)
  },
  getAllEntries (page = 1) {
    return Api().get(`accounts/entries?page=${page}`)
  }
}
